@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  width: 1717px;
  overflow: hidden;
  position: relative;
  color: var(--black, #000);
  font-family: D2Coding;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: lowercase;
  white-space: nowrap;
}
/* 3434/2 = 1717 */
.propertyStart {
  display: inline-block;
  width: 1717px;
  animation: marquee 20s linear infinite;
}

.logoWrapper {
  display: inline-block;
  position: relative;
}
.logoWrapper b {
  margin-right: 20px;
}

.logoWrapper img {
  width: auto;
  margin-right: 20px;
  vertical-align: middle;
}
