:hover,
:focus,
:visited,
:link,
:active {
  cursor:
    url('/assets/images/mouse-active.png') 16 16,
    auto !important;
}

.rootSection {
  width: 100%;
  overflow: hidden;
}
.homeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1440px;
  margin: 0 auto;
}
// menu
.menuSection {
  position: fixed;
  top: 0;
  width: 1440px;
  z-index: 999;
  mix-blend-mode: difference;
  .menuBox {
    display: flex;
    flex-direction: row;
    height: 82px;
    align-items: center;
    justify-content: space-between;
    padding: 24px 28px;
    box-sizing: border-box;
    font-size: var(--font-size-7xl);
    color: var(--green);
    font-family: var(--font-d2coding);
    img {
      margin-top: -17px;
    }
  }
  .menuLogo {
    width: 300px;
    position: relative;
    height: 34px;
    overflow: hidden;
    flex-shrink: 0;
    mix-blend-mode: difference;
    transition: opacity 1s ease-in-out;
    position: absolute;
  }
  .menuLogoHidden {
    opacity: 0;
  }
  .menuItemWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-81xl);
    mix-blend-mode: difference;
    a {
      color: var(--green);
      text-decoration: none;
      position: relative;
    }
    .menuItem {
      position: relative;
    }
  }
}
// main
.mainSection {
  width: 100vw;
  background-color: var(--blue);
  overflow: hidden;
  .mainWrapper {
    width: 1384px;
    margin: 0 auto;
    padding: 200px 28px;
    position: relative;
  }
  .texture {
    position: absolute;
    top: 0;
    left: -380px;
    width: 1100px;
    // height: 1294px;
    object-fit: contain;
    opacity: 0.5;
    mix-blend-mode: color-burn;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .texture2 {
    position: absolute;
    top: 0;
    left: 720px;
    width: 1100px;
    // height: 1294px;
    object-fit: contain;
    opacity: 0.5;
    mix-blend-mode: color-burn;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .texture3 {
    position: absolute;
    top: 0;
    left: 1820px;
    width: 1100px;
    // height: 1294px;
    object-fit: contain;
    opacity: 0.5;
    mix-blend-mode: color-burn;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .texture4 {
    position: absolute;
    top: 0;
    left: -1480px;
    width: 1100px;
    // height: 1294px;
    object-fit: contain;
    opacity: 0.5;
    mix-blend-mode: color-burn;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .vectorIcon {
    position: absolute;
    bottom: 0;
    left: -240px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .titleWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 114px;
    position: relative;

    .title {
      color: var(--white, #fff);
      font-family: Geomanist;
      font-size: 110px;
      font-style: normal;
      font-weight: 900;
      line-height: 124px;
      display: inline-block;
    }
    .date {
      color: var(--white, #fff);
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 8px;
    }
  }
  .buttonWrapper {
    padding-top: 140px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 24px;
    position: relative;

    .ticketBtn {
      position: inherit;
      width: 236px;
      height: 126px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.44px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 236px;
        height: 126px;
        line-height: 126px;
      }
    }
    .ticketBtn2 {
      position: inherit;
      width: 236px;
      height: 126px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.44px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 236px;
        height: 126px;
        line-height: 126px;
      }
    }
    .ticketBtn3 {
      position: absolute;
      right: 0;
      width: 364px;
      height: 152px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.44px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 10px;
        width: 364px;
        height: 142px;
        line-height: 142px;
      }
    }
  }
}
// envisioning
.envisioningSection {
  width: calc(100% - 56px);
  padding: 200px 28px 0 28px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .explosionIcon {
    width: 472px;
    height: 468px;
    margin-top: 169px;
    left: 90px;
    position: absolute;
  }
  .explosionIcon1 {
    width: 320px;
    height: 314px;
    margin-top: 20px;
    right: 0;
    position: absolute;
  }
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 108px;
    font-style: normal;
    font-weight: 900;
    line-height: 110px; /* 101.852% */
    letter-spacing: 1.62px;
    text-transform: uppercase;
  }
  .subTitle {
    color: var(--black, #000);
    font-family: Pretendard;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 134.615% */
    padding-top: 20px;
    position: relative;
  }
  .envisioningImg {
    width: 100%;
    padding-top: 80px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .subDec {
    color: var(--black, #000);
    font-family: Pretendard;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 134.615% */
    padding-top: 80px;
    position: relative;
  }
  .explosionVideo {
    width: 100%;
    border-radius: 40px;
    // background: var(--pink, #ff48a0);
    position: relative;
    margin-top: 40px;
  }
  .videoContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 */
    border-radius: 40px;
  }
  .videoContainer video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .thumbnailContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .thumbnailContainer:hover {
    .playButton {
      background: var(--blue, #5151e5);
      color: var(--white, #fff);
    }
  }
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white, #fff);
    pointer-events: none;
    display: inline-flex;
    padding: 20px 32px;
    justify-content: center;
    align-items: center;
    color: var(--black, #000);
    text-align: center;
    font-family: var(--font-d2coding);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 10px;
  }
  .logoAnimation {
    padding-top: 40px;
    overflow: hidden;
  }
}
// conference
.conferenceSection {
  width: calc(100% - 56px);
  padding: 220px 28px 0 28px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 108px;
    font-style: normal;
    font-weight: 900;
    line-height: 110px; /* 101.852% */
    letter-spacing: 1.62px;
    text-transform: uppercase;
  }
  .conferenceVideo {
    width: 100%;
    border-radius: 40px;
    background: var(--pink, #ff48a0);
    position: relative;
    margin-top: 40px;
  }
  .videoContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 */
    border-radius: 40px;
  }
  .videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .thumbnailContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .thumbnailContainer:hover {
    .playButton {
      background: var(--white, #fff);
      color: var(--black, #000);
    }
  }
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    pointer-events: none;
    display: inline-flex;
    padding: 20px 32px;
    justify-content: center;
    align-items: center;
    color: var(--white, #fff);
    text-align: center;
    font-family: var(--font-d2coding);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 10px;
    background: var(--pink, #ff48a0);
  }
  .conferenceImg {
    width: 100%;
    padding-top: 20px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .conferenceMap {
    width: 100%;
    padding-top: 20px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .conferenceVector {
    position: absolute;
    right: 0;
    width: 27%;
    margin-top: -5.5%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .logoAnimation {
    padding-top: 40px;
    overflow: hidden;
  }
}
// networking
.networkingSection {
  width: calc(100% - 56px);
  padding: 200px 28px 0 28px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 108px;
    font-style: normal;
    font-weight: 900;
    line-height: 110px; /* 101.852% */
    letter-spacing: 1.62px;
    text-transform: uppercase;
  }
  .networkingImg {
    width: 100%;
    padding-top: 40px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
}
// speakers
.speakersSection {
  width: 100%;
  padding: 200px 0 0 0;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 108px;
    font-style: normal;
    font-weight: 900;
    line-height: 110px; /* 101.852% */
    letter-spacing: 1.62px;
    text-transform: uppercase;
    padding: 0 28px;
  }
  .speakersWrapper {
    padding: 40px 28px 0 28px;
    display: flex;
    flex-wrap: wrap;
  }
  .speakerCard {
    display: inline-block;
    margin-right: 60px;
    margin-bottom: 40px;
    .speakerName {
      color: var(--black, #000);
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px; /* 125% */
      margin-top: 24px;
    }
    .speakerPosition {
      color: var(--black, #000);
      font-family: D2Coding;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 125% */
      letter-spacing: -0.96px;
      margin-top: 8px;
    }
    .speakerImg {
      width: 300px;
      height: 400px;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .speakerCard2 {
    display: inline-block;
    margin-bottom: 40px;
    .speakerName {
      color: var(--black, #000);
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 40px; /* 125% */
      margin-top: 24px;
    }
    .speakerPosition {
      color: var(--black, #000);
      font-family: D2Coding;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 125% */
      letter-spacing: -0.96px;
      margin-top: 8px;
    }
    .speakerName2 {
      color: var(--black, #000);
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 30px;
      margin-top: 24px;
      width: 300px;
      .subName {
        color: #484848;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin-left: 8px;
      }
    }
    .speakerPosition2 {
      color: var(--black, #000);
      font-family: D2Coding;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 130% */
      letter-spacing: -0.64px;
      margin-top: 8px;
      width: 300px;
    }
    .speakerImg {
      width: 300px;
      height: 400px;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .speakersImg {
    width: 100%;
    margin-top: 60px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
}
// brought
.broughtSection {
  width: calc(100% - 56px);
  padding: 220px 28px 0 28px;
  margin-top: -152px;
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 108px;
    font-style: normal;
    font-weight: 900;
    line-height: 110px; /* 101.852% */
    letter-spacing: 1.62px;
    text-transform: uppercase;
  }
  .logoWrapper {
    padding-top: 80px;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      // height: 56px;
      width: 100%;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .buttonWrapper {
    padding-top: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 24px;
    position: relative;

    .ticketBtn {
      position: inherit;
      width: 240px;
      height: 130px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.24px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 240px;
        height: 130px;
        line-height: 130px;
      }
    }
  }
}
// supporters
.supportersSection {
  width: calc(100% - 56px);
  padding: 220px 28px 0 28px;
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 108px;
    font-style: normal;
    font-weight: 900;
    line-height: 110px; /* 101.852% */
    letter-spacing: 1.62px;
    text-transform: uppercase;
  }
  .logoWrapper {
    padding-top: 80px;
    display: flex;
    gap: 140px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      // height: 56px;
      width: 100%;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .buttonWrapper {
    padding-top: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 24px;
    position: relative;

    .ticketBtn {
      position: inherit;
      width: 240px;
      height: 130px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.24px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 240px;
        height: 130px;
        line-height: 130px;
      }
    }
  }
}
// contact
.contactSection {
  width: calc(100% - 56px);
  padding: 220px 28px 0 28px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .contactWrapper {
    border-radius: 40px;
    padding: 60px;
    background: var(--red, #f21346);
    .contactBg {
      width: calc(100% - 56px);
      height: 780px;
      position: absolute;
      top: 220px;
      left: 28px;
      border-radius: 40px;
      background: linear-gradient(326deg, #f21346 6.65%, rgba(242, 19, 70, 0) 52.37%);
    }
    .vectorIcon {
      position: absolute;
      bottom: 0;
      right: 28px;
      border-radius: 26px;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
    .texture {
      position: absolute;
      top: 0;
      left: -200px;
      width: 1000px;
      height: 1294px;
      object-fit: contain;
      opacity: 0.5;
      mix-blend-mode: color-burn;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
    .texture2 {
      position: absolute;
      top: 0;
      left: 800px;
      width: 1000px;
      height: 1294px;
      object-fit: contain;
      opacity: 0.5;
      mix-blend-mode: color-burn;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
    .title {
      color: var(--white, #fff);
      text-align: justify;
      font-family: Geomanist;
      font-size: 108px;
      font-style: normal;
      font-weight: 900;
      line-height: 110px; /* 101.852% */
      letter-spacing: 1.62px;
      text-transform: uppercase;
      position: relative;
    }

    .buttonWrapper {
      padding-top: 290px;
      justify-content: flex-start;
      flex-direction: row;
      position: relative;

      .ticketBtn {
        position: inherit;
        width: 240px;
        height: 130px;
        color: var(--black, #000);
        text-align: center;
        font-family: D2Coding;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.24px;
        display: inline-block;
        margin-right: 43px;
        margin-top: 20px;
        a {
          color: var(--black, #000);
        }
        b {
          position: absolute;
          top: 0;
          left: 0;
          width: 240px;
          height: 130px;
          line-height: 130px;
        }
      }
    }
  }
  .qrIcon {
    position: absolute;
    bottom: 60px;
    right: 88px;
    width: 234px;
    height: 234px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
}
// logoAnimation
.logoAnimationSection {
  width: calc(100% - 56px);
  padding: 0 28px 0 28px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .logoAnimation {
    padding-top: 40px;
    overflow: hidden;
  }
}
// register
.registerSection {
  width: calc(100% - 56px);
  padding: 200px 28px 0 28px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 180px;
    font-style: normal;
    font-weight: 900;
    line-height: 180px; /* 100% */
    text-transform: uppercase;
  }
  .buttonWrapper {
    position: relative;
    position: absolute;
    top: 363px;
    left: 563px;

    .ticketBtn {
      position: inherit;
      width: 375px;
      height: 152px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.24px;
      display: inline-block;
      margin-right: 43px;
      margin-top: 20px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 375px;
        height: 152px;
        line-height: 152px;
      }
    }
  }
}
// footer
.footerSection {
  width: 100vw;
  background: var(--black, #000);
  position: relative;
  overflow: hidden;
  margin-top: 24px;
  .footerWrapper {
    width: 1384px;
    padding: 40px 28px 140px 28px;
    margin: 0 auto;

    .title {
      color: var(--white, #fff);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 150% */
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 1440px) {
  .homeSection {
    width: 100%;
  }
  .menuSection {
    width: 100%;
  }
  .mainSection {
    .mainWrapper {
      width: calc(100% - 56px);
    }
  }
  .speakersSection {
    .speakerCard2 {
      margin-right: 60px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .menuSection .menuItemWrapper {
    gap: 20px;
  }
  .contactSection .contactWrapper .vectorIcon {
    width: 100%;
    border-bottom-left-radius: 122px;
  }
  .contactSection .contactWrapper .buttonWrapper {
    width: 330px;
  }
  .contactSection .contactWrapper .contactBg {
    height: 930px;
  }
}

// menu
.menuSection_m {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  mix-blend-mode: difference;
  .menuBox {
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    padding: 8px 10px 8px 12px;
    box-sizing: border-box;
    color: var(--green);
    font-family: var(--font-d2coding);
  }
  .menuLogo {
    margin-top: 3px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    mix-blend-mode: difference;
    transition: opacity 1s ease-in-out;
    position: absolute;
  }
  .menuLogoHidden {
    opacity: 0;
  }
  .menuIconWrapper {
    position: absolute;
    right: 36px;
  }
  .menuIcon {
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    mix-blend-mode: difference;
    transition: opacity 1s ease-in-out;
    position: absolute;
  }
  .menuIconHidden {
    opacity: 0;
  }
  .menuItemWrapper {
    position: absolute;
    top: 40px;
    padding: 12px 0;
    width: calc(100% - 24px);
    display: flex;
    gap: calc((100% - 180px) / 4);
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    mix-blend-mode: difference;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition:
      max-height 1s ease,
      opacity 1s ease;

    &.menuOpen {
      max-height: 100px;
      opacity: 1;
    }
    a {
      color: var(--green);
      text-decoration: none;
      position: relative;
    }
    .menuItem {
      position: relative;
    }
  }
}
// main
.mainSection_m {
  width: calc(100% - 24px);
  padding: 176px 12px 55px 12px;
  background-color: var(--blue);
  position: relative;
  overflow: hidden;
  .texture {
    position: absolute;
    top: 0;
    left: -150px;
    width: 500px;
    object-fit: contain;
    opacity: 0.5;
    mix-blend-mode: color-burn;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .texture2 {
    position: absolute;
    top: 0;
    left: 350px;
    width: 500px;
    object-fit: contain;
    opacity: 0.5;
    mix-blend-mode: color-burn;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .vectorIcon {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .titleWrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    .title {
      color: var(--white, #fff);
      text-align: center;
      font-family: Geomanist;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 44px;
      img {
        margin-bottom: 8px;
      }
    }
    .date {
      color: var(--white, #fff);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 8px;
    }
  }
  .buttonWrapper {
    padding-top: 82px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 13px;
    position: relative;
    .ticketBtn {
      position: inherit;
      width: 124px;
      height: 66px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.13px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 124px;
        height: 66px;
        line-height: 66px;
      }
    }
    .ticketBtn2 {
      position: inherit;
      width: 115px;
      height: 66px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.13px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 115px;
        height: 66px;
        line-height: 66px;
      }
    }
  }
  .buttonWrapper2 {
    padding-top: 13px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
    .ticketBtn3 {
      position: inherit;
      width: 165px;
      height: 62px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.52px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        padding-top: 4px;
        top: 0;
        left: 0;
        width: 165px;
        height: 58px;
        line-height: 58px;
      }
    }
  }
}
// envisioning
.envisioningSection_m {
  width: calc(100% - 24px);
  padding: 100px 12px 0 12px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .explosionIcon {
    width: 226px;
    height: 222px;
    margin-top: 10px;
    right: 12px;
    position: absolute;
  }
  .explosionIcon1 {
    width: 284px;
    height: 282px;
    margin-top: 278px;
    left: 12px;
    position: absolute;
  }
  .title {
    color: var(--black, #000);
    text-align: center;
    font-family: Geomanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 111.111% */
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
  .subTitle {
    color: var(--black, #000);
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-top: 8px;
  }
  .envisioningImg {
    width: 100%;
    padding-top: 60px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .subDec {
    color: var(--black, #000);
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding-top: 60px;
    position: relative;
  }
  .explosionVideo {
    width: 100%;
    border-radius: 16px;
    // background: var(--pink, #ff48a0);
    position: relative;
    margin-top: 8px;
  }
  .videoContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 */
    border-radius: 16px;
  }
  .videoContainer video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .thumbnailContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--white, #fff);
    pointer-events: none;
    display: inline-flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    color: var(--black, #000);
    text-align: center;
    font-family: var(--font-d2coding);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
  }
  .logoAnimation {
    padding-top: 14px;
    overflow: hidden;
  }
}
// conference
.conferenceSection_m {
  width: calc(100% - 24px);
  padding: 120px 12px 0 12px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    text-align: center;
    font-family: Geomanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 111.111% */
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
  .conferenceVideo {
    width: 100%;
    border-radius: 16px;
    background: var(--pink, #ff48a0);
    position: relative;
    margin-top: 12px;
  }
  .videoContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 */
    border-radius: 16px;
  }
  .videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  .thumbnailContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    pointer-events: none;
    display: inline-flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    color: var(--white, #fff);
    text-align: center;
    font-family: var(--font-d2coding);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 4px;
    background: var(--pink, #ff48a0);
  }
  .conferenceImg {
    width: 100%;
    padding-top: 8px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .conferenceMap {
    width: 100%;
    padding-top: 8px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .conferenceVector {
    position: absolute;
    right: -12%;
    width: 30%;
    margin-top: -7.5%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
  .logoAnimation {
    padding-top: 14px;
    overflow: hidden;
  }
}
// networking
.networkingSection_m {
  width: calc(100% - 24px);
  padding: 120px 12px 0 12px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    text-align: center;
    font-family: Geomanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 111.111% */
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
  .networkingImg {
    width: 100%;
    padding-top: 16px;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }
}
// speakers
.speakersSection_m {
  width: 100%;
  padding: 120px 0 0 0;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    text-align: center;
    font-family: Geomanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 111.111% */
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
  .speakersWrapper {
    padding: 16px 12px 0 12px;
    display: flex;
    flex-wrap: wrap;
  }
  .speakerCard {
    display: inline-block;
    margin-bottom: 24px;
    width: calc(50% - 4px);
    .speakerName {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      margin-top: 8px;
    }
    .speakerPosition {
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.48px;
      margin-top: 8px;
    }
    .speakerImg {
      width: 100%;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .speakerCard2 {
    display: inline-block;
    margin-bottom: 24px;
    width: calc(50% - 4px);
    .speakerName {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      margin-top: 8px;
    }
    .speakerPosition {
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.48px;
      margin-top: 8px;
    }
    .speakerName2 {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      margin-top: 8px;
      .subName {
        color: #484848;

        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin-left: 8px;
      }
    }
    .speakerPosition2 {
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 145.455% */
      letter-spacing: -1.44px;
      margin-top: 8px;
    }
    .speakerImg {
      width: 100%;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .speakersImg {
    width: 100%;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
    margin-top: 12px;
  }
}
// brought
.broughtSection_m {
  width: calc(100% - 24px);
  padding: 120px 12px 0 12px;
  margin-top: -80px;
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    text-align: center;
    font-family: Geomanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 111.111% */
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
  .logoWrapper {
    padding-top: 40px;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    img {
      width: 100%;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .buttonWrapper {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 24px;
    position: relative;
    .ticketBtn {
      position: inherit;
      width: 122px;
      height: 65px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 123.077% */
      letter-spacing: -0.13px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 122px;
        height: 65px;
        line-height: 65px;
      }
    }
  }
}
// supporters
.supportersSection_m {
  width: calc(100% - 24px);
  padding: 120px 12px 0 12px;
  position: relative;
  overflow: hidden;
  .title {
    color: var(--black, #000);
    text-align: center;
    font-family: Geomanist;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px; /* 111.111% */
    letter-spacing: 0.54px;
    text-transform: uppercase;
  }
  .logoWrapper {
    padding-top: 40px;
    display: flex;
    gap: 36px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    img {
      width: 100%;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
  }
  .buttonWrapper {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 24px;
    position: relative;
    .ticketBtn {
      position: inherit;
      width: 244px;
      height: 32px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
      border-radius: 100px;
      background: var(--green, #99fd45);
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 244px;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
// contact
.contactSection_m {
  width: calc(100% - 24px);
  padding: 120px 12px 0 12px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .contactWrapper {
    border-radius: 16px;
    padding: 36px 0 24px 0;
    background: var(--red, #f21346);
    .contactBg {
      width: calc(100% - 56px);
      height: 780px;
      position: absolute;
      top: 200px;
      left: 28px;
      border-radius: 16px;
      background: linear-gradient(326deg, #f21346 6.65%, rgba(242, 19, 70, 0) 52.37%);
    }
    .vectorIcon {
      position: absolute;
      bottom: 0;
      // right: 28px;
      width: calc(100% - 24px);
      height: 427px;
      border-radius: 26px;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
    .texture {
      position: absolute;
      top: 0;
      left: -150px;
      width: 500px;
      object-fit: contain;
      opacity: 0.5;
      mix-blend-mode: color-burn;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
    .texture2 {
      position: absolute;
      top: 0;
      left: 350px;
      width: 500px;
      object-fit: contain;
      opacity: 0.5;
      mix-blend-mode: color-burn;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
    }
    .title {
      color: var(--white, #fff);
      text-align: center;
      font-family: Geomanist;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: 40px; /* 111.111% */
      letter-spacing: 0.54px;
      text-transform: uppercase;
      position: relative;
    }
    .subTitle {
      color: var(--white, #fff);
      text-align: center;
      font-family: D2Coding;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.14px;
      margin-top: 58px;
      display: flex;
      justify-content: center;
      position: relative;
    }
    .qrIcon {
      width: 113px;
      height: 113px;
      display: flex;
      margin: 0 auto;
      margin-top: 8px;
      -webkit-user-drag: none;
      -moz-user-drag: none;
      -ms-user-drag: none;
      user-drag: none;
      position: relative;
    }

    .buttonWrapper {
      padding-top: 60px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 13px;
      position: relative;

      .ticketBtn {
        position: inherit;
        width: 122px;
        height: 65px;
        color: var(--black, #000);
        text-align: center;
        font-family: D2Coding;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 123.077% */
        letter-spacing: -0.13px;
        a {
          color: var(--black, #000);
        }
        b {
          position: absolute;
          top: 0;
          left: 0;
          width: 122px;
          height: 65px;
          line-height: 65px;
        }
      }
      .ticketBtn2 {
        position: inherit;
        width: 116px;
        height: 62px;
        color: var(--black, #000);
        text-align: center;
        font-family: D2Coding;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 123.077% */
        letter-spacing: -0.13px;
        a {
          color: var(--black, #000);
        }
        b {
          position: absolute;
          top: 0;
          left: 0;
          width: 116px;
          height: 62px;
          line-height: 62px;
        }
      }
    }
  }
}
// logoAnimation
.logoAnimationSection_m {
  width: calc(100% - 24px);
  padding: 0 12px 0 12px;
  background: var(--white, #fff);
  position: relative;
  overflow: hidden;
  .logoAnimation {
    padding-top: 14px;
    overflow: hidden;
  }
}
// register
.registerSection_m {
  width: calc(100% - 24px);
  padding: 120px 12px 0 12px;
  background: var(--white, #fff);
  position: relative;
  .title {
    color: var(--black, #000);
    font-family: Geomanist;
    font-size: 56px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px; /* 107.143% */
    text-transform: uppercase;
  }
  .buttonWrapper {
    position: relative;
    position: absolute;
    top: 173px;
    left: 183px;

    .ticketBtn {
      position: inherit;
      width: 148px;
      height: 62px;
      color: var(--black, #000);
      text-align: center;
      font-family: D2Coding;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.56px;
      a {
        color: var(--black, #000);
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        width: 148px;
        height: 62px;
        line-height: 62px;
      }
    }
  }
}
// footer
.footerSection_m {
  width: calc(100% - 56px);
  padding: 20px 28px 100px 28px;
  margin-top: 20px;
  background: var(--black, #000);
  position: relative;
  overflow: hidden;
  .title {
    color: var(--white, #fff);
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 120% */
    text-transform: uppercase;
  }
}
