@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Thin.eot');
  src:
    local('Geomanist Thin'),
    local('Geomanist-Thin'),
    url('/assets/font/Geomanist-Thin.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Thin.woff2') format('woff2'),
    url('/assets/font/Geomanist-Thin.woff') format('woff'),
    url('/assets/font/Geomanist-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Black.eot');
  src:
    local('Geomanist Black'),
    local('Geomanist-Black'),
    url('/assets/font/Geomanist-Black.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Black.woff2') format('woff2'),
    url('/assets/font/Geomanist-Black.woff') format('woff'),
    url('/assets/font/Geomanist-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Medium.eot');
  src:
    local('Geomanist Medium'),
    local('Geomanist-Medium'),
    url('/assets/font/Geomanist-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Medium.woff2') format('woff2'),
    url('/assets/font/Geomanist-Medium.woff') format('woff'),
    url('/assets/font/Geomanist-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Bold.eot');
  src:
    local('Geomanist Bold'),
    local('Geomanist-Bold'),
    url('/assets/font/Geomanist-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Bold.woff2') format('woff2'),
    url('/assets/font/Geomanist-Bold.woff') format('woff'),
    url('/assets/font/Geomanist-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist Book';
  src: url('/assets/font/Geomanist-Book.eot');
  src:
    local('Geomanist Book'),
    local('Geomanist-Book'),
    url('/assets/font/Geomanist-Book.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Book.woff2') format('woff2'),
    url('/assets/font/Geomanist-Book.woff') format('woff'),
    url('/assets/font/Geomanist-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist Ultra';
  src: url('/assets/font/Geomanist-Ultra.eot');
  src:
    local('Geomanist Ultra'),
    local('Geomanist-Ultra'),
    url('/assets/font/Geomanist-Ultra.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Ultra.woff2') format('woff2'),
    url('/assets/font/Geomanist-Ultra.woff') format('woff'),
    url('/assets/font/Geomanist-Ultra.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Regular.eot');
  src:
    local('Geomanist Regular'),
    local('Geomanist-Regular'),
    url('/assets/font/Geomanist-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Regular.woff2') format('woff2'),
    url('/assets/font/Geomanist-Regular.woff') format('woff'),
    url('/assets/font/Geomanist-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-ExtraLight.eot');
  src:
    local('Geomanist ExtraLight'),
    local('Geomanist-ExtraLight'),
    url('/assets/font/Geomanist-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-ExtraLight.woff2') format('woff2'),
    url('/assets/font/Geomanist-ExtraLight.woff') format('woff'),
    url('/assets/font/Geomanist-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/font/Geomanist-Light.eot');
  src:
    local('Geomanist Light'),
    local('Geomanist-Light'),
    url('/assets/font/Geomanist-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/Geomanist-Light.woff2') format('woff2'),
    url('/assets/font/Geomanist-Light.woff') format('woff'),
    url('/assets/font/Geomanist-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
@import url('//cdn.jsdelivr.net/gh/wan2land/d2coding/d2coding-full.css');

html {
  scroll-behavior: smooth;
  cursor:
    url('/assets/images/mouse.png') 16 16,
    auto !important;
}
body {
  margin: 0;
  line-height: normal;
}

:hover,
:focus,
:visited,
:link,
:active {
  cursor:
    url('/assets/images/mouse-active.png') 16 16,
    auto !important;
}
:root {
  /* fonts */
  --font-d2coding: D2Coding;
  --font-pretendard: Pretendard;
  --font-geomanist: Geomanist;

  /* font sizes */
  --font-size-7xl: 26px;
  --font-size-5xl: 24px;
  --font-size-13xl: 32px;
  --font-size-89xl: 108px;
  --font-size-41xl: 60px;
  --font-size-9xl: 28px;
  --font-size-33xl: 52px;

  /* Colors */
  --white: #fff;
  --green: #99fd45;
  --black: #000;
  --blue: #5151e5;
  --red: #f21346;
  --pink: #ff48a0;

  /* Gaps */
  --gap-81xl: 100px;
  --gap-5xs: 8px;
  --gap-21xl: 40px;
  --gap-61xl: 80px;
  --gap-5xl: 24px;
  --gap-xl: 20px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-21xl: 40px;
  --padding-9xl: 28px;
  --padding-xl: 20px;

  /* Border radiuses */
  --br-21xl: 40px;
  --br-xl: 20px;
  --br-3xs: 10px;

  /* Effects */
  --style: blur(40px);
}
