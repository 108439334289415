@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  width: 670px;
  overflow: hidden;
  position: relative;
  color: var(--black, #000);
  font-family: D2Coding;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
  text-transform: lowercase;
  white-space: nowrap;
}

.propertyStart {
  display: inline-block;
  width: 670px;
  animation: marquee 20s linear infinite;
}

.logoWrapper {
  display: inline-block;
  position: relative;
}
.logoWrapper b {
  margin-right: 8px;
}

.logoWrapper img {
  width: auto;
  margin-right: 8px;
  vertical-align: middle;
}
